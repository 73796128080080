.text {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;  
  color: rgba(99, 98, 119, 1);
}

.left {
  text-align: right;
}
.right {
  text-align: left;
}
.center {
  text-align: center;
}