.outline {
  padding: 4px 0px;
  text-align: center;
  text-decoration: none;
  font-style: normal;
  font-size: 18px;
  line-height: 32px;
  font-weight: 500;
  color: rgba(99, 98, 119, 1);
  transition: all 0.4;
  &:hover {
    color: rgb(85, 206, 253);
    text-decoration: underline;
  }
  transition: all 0.4s;
}

.back {
  padding: 4px 0px;
  width: 100px;
  text-align: center;
  text-decoration: none;
  border-color: black;
  border-bottom: 2px solid;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  transition: all 0.4;
  &:hover {
    background-color: #ffffffe7;
    padding: 4px 24px;
    text-decoration: none;
  }
  transition: all 0.4s;
}

.white {
  color: white;
}
